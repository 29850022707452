.App {
  text-align: center;
}

.logo {
  /*height: 40vmin;
  pointer-events: none;*/
  /*position: absolute;
  top: 1px;
  left: 1px;
  height: 150px;
  transition: 0.4s;*/
  float:left;
  top: -92px;
  left: -37px;
  height: 37vmin;
  position: absolute;
  transition: 0.4s;
}

.icon {
  /*height: 40vmin;
  pointer-events: none;*/
  /*position: absolute;
  top: 1px;
  left: 1px;
  height: 150px;
  transition: 0.4s;*/
  float:left;
  top: 4px;
  left: 15px;
  height: 5vmin;
  position: relative;
}

#navbar {
  overflow: hidden;
  background-color:  #f5f5f5;
  transition: 0.4s;
  position: fixed; /*static,relative, absolute,sticky, fixed*/
  width: 100%;
  height: 40px;
  top:0px;
}

#navbar a {
  
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 10px;
  text-decoration: none;
  font-size: 20px; 
  line-height: 15px;
  border-radius: 3px;
}

#navbar a:hover {
  color: rgb(255, 255, 255);
}



.header {
  background-color: #f5f5f5;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}


.parallax {

  /* Set a specific height */ 
  height: 70vh;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.parallax1 {

  /* Set a specific height */
  height: 70vh;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

body {
  height: 4000px;
  background: linear-gradient(180deg, #fcfcfc 10%, #dbdbdb 45%, #bdbdbd 75%);
}